
import React, { useEffect, useState } from 'react';
import axios from 'axios';  // Import axios to make API requests
import headerImage from '../../assets/media/oxyera_pack.jpg';
import Navbar from '../../components/Navbar';
import Footer from "../../components/Footer";
import { Helmet } from 'react-helmet';
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";
import mobileApp from '../../assets/media/oxyera-ordinazione-medicinali-online-app.png';
import medicalService from '../../assets/media/farmacista-donna.png';
import ContactForm from '../../components/contactForm/ContactForm';
import appStore from '../../assets/icons/app-store.png';
import playStore from '../../assets/icons/google-play.png';
import HoursIcon from '../../assets/icons/home1.png';
import deliveryIcon from '../../assets/icons/home3.png';
import calendarIcon from '../../assets/icons/home2.png';
import coomingSoon from '../../assets/icons/home4.png';
import { BiCartAdd } from "react-icons/bi";
import oxyeraIcon from '../../assets/icons/android-chrome-192x192.png';
// import { useAuth } from '../../context/AuthContext';
import { IoCloseCircle } from "react-icons/io5";
import Lottie from 'react-lottie';
import * as loadingAnimation from '../../assets/lottie/loading-white.json';  // Add your Lottie JSON file
import { ScrollParallax } from 'react-just-parallax';
import img6 from '../../assets/media/sezione1.png';
import img7 from '../../assets/icons/1 (1).png';
import img8 from '../../assets/icons/2 (1).png';
import img9 from '../../assets/icons/3 (1).png';
import farmacyImage from '../../assets/media/home-farmacy.png';
import DefaultButton from '../../components/DefaultButton';
import businessImage from '../../assets/media/home-business.png';
import { useNavigate } from 'react-router-dom';


function HomePage() {
  // const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust breakpoint as needed
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  // const { addToCart } = useAuth();
  // const [addedItemId, setAddedItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(0);
  const navigate = useNavigate();  // Initialize useNavigate

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    // Debounce logic
    const handler = setTimeout(() => {
      if (searchTerm.length > 1) {
        handleSearch();
      } else {
        setSuggestions([]);
      }
    }, 300); // Debounce delay (300ms)

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSearch = async () => {
    setIsLoading(1);
    try {
      const response = await axios.get(`https://app.oxyera.com/medicine/search/${searchTerm}`);
      setSuggestions(response.data);
      setIsLoading(0);
    } catch (error) {
      setIsLoading(2);
      console.error("Error fetching search results", error);
    }
  };

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleItemClick();
    }
  };

  // const handleAddToCart = (item) => {
  //   addToCart(item);
  //   setAddedItemId(item.aic);
        
  //   setTimeout(() => {
  //     setAddedItemId(null);
  //   }, 1000);
  // };

  const clearSearch = () => {
    setSearchTerm('');
    setSuggestions([]);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleClick = (e) => {
    e.preventDefault(); // Prevent the default link behavior

    // Construct the mailto link
    const mailtoLink = 'mailto:info@oxyera.com?subject=Richiesta%20Informazioni';

    // Open the email client
    window.location.href = mailtoLink;
  };

  const handleItemClick = () => {
    if (isMobile) {
      navigate(`/mobile/prodotti/?search=${searchTerm}`);
    } else {
      navigate(`/prodotti/?search=${searchTerm}`);
    }
  };

  return (
    <div className="">
      <Helmet>
        <title>Oxyera</title>
        <meta name="description" content="Oxyera - La tua farmacia online di fiducia. Ordina i tuoi farmaci in modo semplice e sicuro, scegli la consegna a domicilio o il ritiro in farmacia. Scopri la comodità dell'acquisto online con Oxyera!" />
        <meta property="og:title" content="Oxyera" />
        <meta property="og:description" content="Oxyera - La tua farmacia online di fiducia. Ordina i tuoi farmaci in modo semplice e sicuro, scegli la consegna a domicilio o il ritiro in farmacia. Scopri la comodità dell'acquisto online con Oxyera!" />
        <meta property="og:image" content={Logo} /> 
        <meta property="og:url" content="https://oxyera.com/" />
        <meta property="og:type" content="website" />
        <meta name="keywords" content="farmacia online, acquisto farmaci online, consegna farmaci, ritiro in farmacia, farmaci sicuri, farmacia conveniente, acquisto online farmaci, Oxyera farmacia" />
        <link rel="canonical" href="https://oxyera.com/" />
      </Helmet>
      <Navbar />

      <section>
        <div className='mx-auto'>
          <div className='h-screen flex justify-center items-center relative'>
            <div className='h-full w-full xl:w-1/2 bg-blue-oxyera flex justify-center items-center p-4 z-20'>
              <div className='text-left w-full lg:max-w-xl mx-auto mb-14 sm:mb-0'>
                <div className="flex justify-start items-center w-56 me-0 sme-2">
                  <img src={Logo} alt="Logo" className="object-contain h-20 sm:h-36 w-auto" />
                  <h1 className="ml-3 text-4xl sm:text-6xl font-semibold text-white">Oxyera</h1>
                </div>
                <div data-aos="fade-zoom-in" data-aos-duration="1000" className='mb-4 font-medium py-9 ms-4 sm:ms-0 text-white text-balance'>
                  {/* <p className='font-semibold text-3xl'>I tuoi farmaci a portata di un click</p>
                  <p className='text-xl'>Facile, veloce, sicuro: la nuova era della farmacia digitale.</p> */}
                  <p className='text-3xl'><strong>Ordina facilmente</strong> e ricevi a casa o ritira in farmacia</p>
                </div>
                <div className='mb-4 flex relative'>
                  <input 
                    type='text' 
                    placeholder='Cerca un prodotto' 
                    className='flex-grow p-4 border border-gray-300 rounded-l-lg text-lg'
                    value={searchTerm}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                  <button 
                    className={`absolute right-24 text-gray-500 ${searchTerm ? 'visible' : 'invisible'}`}
                    onClick={clearSearch}
                  >
                    <IoCloseCircle className='text-gray-400 mt-[1.2rem] text-2xl' />
                  </button>
                  <button className='bg-orange-500 text-white p-4 rounded-r-lg text-lg font-semibold' onClick={handleSearch}>
                    Cerca
                  </button>
                  {isLoading === 1 ? (
                    <div className="absolute top-full left-0 right-0 flex justify-center mt-4">
                      <Lottie options={defaultOptions} height={100} width={100} />
                    </div>
                  ) : isLoading === 2 && (
                      <div className="absolute left-0 right-0 top-full bg-white border border-gray-300 rounded-lg shadow-lg mt-2 p-4 text-start text-gray-700">
                        Nessun risultato trovato. <br/>Assicurati di aver digitato correttamente e prova di nuovo.
                      </div>
                  )}
                  {suggestions.length > 0 && (
                    <ul className="absolute left-0 right-0 top-full bg-white border border-gray-300 rounded-lg shadow-lg mt-2 no-scrollbar z-40" style={{ zIndex: 9999, maxHeight: '20rem', overflowY: 'auto' }}>
                      {suggestions.map((item) => (
                        <li 
                          key={item.aic} 
                          className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleItemClick(item)}
                        >
                          <img src={item.url === '' ? oxyeraIcon : item.url} alt={item.drug} className="w-10 h-10 mr-4"/>
                          <span className="flex-grow">{item.drug.replace('*', ' ')}</span>
                          <span className="text-gray-700 font-semibold">{item.public_price.toFixed(2)}€</span>
                          <BiCartAdd className='text-2xl ms-4 text-blue-oxyera' />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>  
            <div className='opacity-90 h-full hidden xl:block sm:w-1/2 z-0 relative'>
              <img src={headerImage} alt='La scelta responsabile per il tuo futuro' className='w-full h-full object-cover h-full' />
              <svg className="absolute inset-y-0 left-0 w-32 h-full text-blue-oxyera" viewBox="0 0 100 100" preserveAspectRatio="none">
                <polygon points="0, 0 100,0 0,100" fill="currentColor" />
              </svg>
            </div>  
          </div>
        </div>
      </section>

{/* 2 section */}
      <div className='relative z-10 h-lvh overflow-hidden'>
        <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
            <img src={img6} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" style={{ transform: 'scale(1.2)' }} />
        </ScrollParallax>
        <div className="absolute top-0 left-0  w-full h-full flex flex-col justify-center items-center text-center px-5">
            
            {/* Centered Title */}
            <h2 className="text-2xl sm:text-4xl lg:text-5xl font-semibold text-blue-oxyera drop-shadow-lg mb-10" data-aos="fade-in" data-aos-duration="1000">
                Come funziona Oxyera?
            </h2>

            {/* Three Images with Titles and Descriptions */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full max-w-6xl sm:mt-20">
                {/* First Image Block */}
                <div className="flex flex-col items-center">
                    <img src={img7} alt="Feature 1" className="w-full h-14 sm:h-36 object-contain mb-4" data-aos="fade-in" data-aos-duration="1200" />
                    <h3 className="text-xl sm:text-2xl font-semibold text-black mb-2" data-aos="fade-in" data-aos-duration="1400">
                    Cerca il farmaco
                    </h3>
                    <p className="text-md sm:text-lg text-black leading-relaxed" data-aos="fade-in" data-aos-duration="1600">
                    Ricerca il prodotto che desideri e ti mostriamo le farmacie più vicine in cui è disponibile
                    </p>
                </div>

                {/* Second Image Block */}
                <div className="flex flex-col items-center">
                    <img src={img8} alt="Feature 2" className="w-full h-14 sm:h-36 object-contain mb-4" data-aos="fade-in" data-aos-duration="1200" />
                    <h3 className="text-xl sm:text-2xl font-semibold text-black mb-2" data-aos="fade-in" data-aos-duration="1400">
                    Scegli la farmacia
                    </h3>
                    <p className="text-md sm:text-lg text-black leading-relaxed" data-aos="fade-in" data-aos-duration="1600">
                    Scegli la farmacia che vuoi ed ordina il tuo farmaco
                    </p>
                </div>

                {/* Third Image Block */}
                <div className="flex flex-col items-center">
                    <img src={img9} alt="Feature 3" className="w-full h-14 sm:h-36 object-contain mb-4" data-aos="fade-in" data-aos-duration="1200" />
                    <h3 className="text-xl sm:text-2xl font-semibold text-black mb-2" data-aos="fade-in" data-aos-duration="1400">
                    Ritiro o consegna
                    </h3>
                    <p className="text-md sm:text-lg text-black leading-relaxed" data-aos="fade-in" data-aos-duration="1600">
                    Ritira in farmacia senza code o ricevi il farmaco direttamente a casa tua con un un click
                    </p>
                </div>
            </div>
        </div>
      </div>



      {/* 3 section */}
      <section>
        <div className='mx-auto'>
          <div className='h-screen flex justify-center items-center'>
            <div className='opacity-90 h-full hidden sm:block sm:w-1/4 z-0'>
              <img src={mobileApp} alt='La scelta responsabile per il tuo futuro' className='w-full h-full object-cover' />
            </div>
            <div className='h-full w-full sm:w-3/4 bg-blue-oxyera flex justify-center items-center p-4 z-0'>
              <div className='text-left w-full sm:max-w-lg lg:max-w-2xl mx-auto mb-44 sm:mb-0'>
                <div className="flex flex-col lg:flex-row justify-start items-start lg:items-center me-0">
                  <img src={coomingSoon} alt="" className='h-14 mb-5 lg:ms-5 lg:mt-0 lg:hidden block'/>
                  <h2 className="text-4xl sm:text-5xl font-semibold text-white">Scarica Oxyera App</h2>
                  <img src={coomingSoon} alt="" className='h-14 mt-5 lg:ms-5 lg:mt-0 hidden lg:block'/>
                </div>
                <div data-aos="fade-zoom-in" data-aos-duration="1000" className='mb-4 font-medium py-9 text-xl sm:text-2xl text-white text-balance'>
                Ordina i tuoi farmaci direttamente dal tuo cellulare!
                </div>
                <div className='flex sm:flex-row justify-start items-center'>
                  {/* <a href='https://www.apple.com/app-store/' target='_blank' rel='noopener noreferrer'> */}
                    <img src={appStore} alt='App Store' className='w-36 sm:w-32 md:w-40 lg:w-48' />
                  {/* </a> */}
                  {/* <a href='https://play.google.com/store' target='_blank' rel='noopener noreferrer'> */}
                    <img src={playStore} alt='Google Play Store' className='w-40 sm:w-32 md:w-40 lg:w-52 ms-4' />
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* 4 section */}
      <section>
        <div className='mx-auto z-0'>
          <div className='h-screen flex flex-col sm:flex-row justify-center items-center'>
            <div className='h-full w-full sm:w-3/4 bg-white flex justify-center items-center p-4 z-20'>
              <div className='text-left w-full sm:max-w-lg lg:max-w-2xl mx-auto py-10 sm:py-0'>
                <div className="flex justify-start items-center mb-5 sm:mb-10">
                  <h2 className="text-3xl sm:text-4xl font-semibold text-blue-oxyera">Servizi</h2>
                </div>
                <ul className='space-y-6 text-blue-oxyera'>
                  <li className='flex items-center'>
                    <img src={HoursIcon} alt="" className='w-14 h-14 mr-4'/>
                    <div>
                      <b className='text-xl sm:text-2xl'>Controllo disponibilità</b><br/>
                      <p className='text-md sm:text-xl'>I tuoi farmaci nella farmacia più vicina sempre disponibili</p>
                    </div>             
                  </li>
                  <li className='flex items-center'>
                    <img src={deliveryIcon} alt="" className='w-14 h-14 mr-4'/>
                    <div>
                      <div className='flex flex-col lg:flex-row justify-start'>
                        {/* <img src={coomingSoon} alt="" className='w-28 block lg:hidden'/> */}
                        <b className='text-xl sm:text-2xl'>Ritira in Farmacia o Consegna</b>
                        {/* <img src={coomingSoon} alt="" className='ms-5 h-10 hidden lg:block'/> */}
                      </div>
                      <p className='text-md sm:text-xl'>Decidi se ritirare i tuoi farmaci o averli direttamente a casa tua</p>
                    </div>             
                  </li>
                  <li className='flex items-center'>
                    <img src={calendarIcon} alt="" className='w-14 h-14 mr-4'/>
                    <div>
                      <div className='flex flex-col lg:flex-row justify-start'>
                        <img src={coomingSoon} alt="" className='w-28 block lg:hidden'/>
                        <b className='text-xl sm:text-2xl'>Visite ed esami</b>
                        <img src={coomingSoon} alt="" className='ms-5 -mt-1 h-10 lg:block hidden'/>
                      </div>
                      <p className='text-md sm:text-xl'>Prenota visite ed esami da casa tua con un clic</p>
                    </div>             
                  </li>
                </ul>
              </div>
            </div>
            <div className='opacity-90 h-full w-full sm:w-1/4 sm:block z-0'>
              <img src={medicalService} alt='La scelta responsabile per il tuo futuro' className='w-full h-full object-cover' />
            </div>
          </div>
        </div>
      </section>

    {/* 5 section */}
      <section>
        <div className='mx-auto'>
          <div className='h-screen flex justify-center items-center'>
            <div className='opacity-90 h-full hidden sm:block sm:w-1/4 z-0'>
              <img src={farmacyImage} alt='La scelta responsabile per il tuo futuro' className='w-full h-full object-cover' />
            </div>
            <div className='h-full w-full sm:w-3/4 bg-blue-oxyera flex justify-center items-center p-4 z-0'>
              <div className='text-left w-full sm:max-w-md lg:max-w-lg xl:max-w-xl mx-auto'>
                <div className="flex justify-start items-center me-0 sme-2">
                  <h2 className="text-4xl sm:text-5xl font-semibold text-white">Sei una farmacia?</h2>
                </div>
                <div data-aos="fade-zoom-in" data-aos-duration="1000" className='mb-4 font-medium py-9 text-lg sm:text-2xl text-white text-balance'>
                Espandi la visibilità della tua farmacia e arricchisci i servizi ai tuoi clienti. Contattaci per scoprire come.
                </div>
                  <button
                    onClick={handleClick}
                    className={`page-link progress-button-light border-slate-50 text-slate-50 px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full`}
                    >
                    Richiedi più informazioni
                  </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 6 section */}
      <section>
        <div className='mx-auto'>
          <div className='h-screen flex justify-center items-center'>
            <div className='h-full w-full sm:w-3/4 bg-white flex justify-center items-center p-4 z-0'>
              <div className='text-left w-full sm:max-w-md lg:max-w-lg xl:max-w-xl mx-auto'>
                <div className="flex justify-start items-center me-0 sme-2">
                  <h2 className="text-2xl sm:text-4xl text-xl sm:text-2xlfont-semibold text-blue-oxyera">Oxyera: l’innovazione nei Servizi Farmaceutici</h2>
                </div>
                <div data-aos="fade-zoom-in" data-aos-duration="1000" className='mb-4 py-9 text-lg sm:text-2xl text-blue-oxyera text-balance'>
                Unisciti a noi nella trasformazione del panorama farmaceutico con una piattaforma all'avanguardia che migliora l'accessibilità e l'efficienza per farmacie e consumatori.                </div>
                  <button
                    onClick={handleClick}
                    className={`page-link border-blue-oxyera text-blue-oxyera progress-button-blue px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full`}
                    >
                    Scopri come investire in Oxyera
                  </button>
              </div>
            </div>
            <div className='opacity-90 h-full hidden sm:block sm:w-1/4 z-0'>
              <img src={businessImage} alt='La scelta responsabile per il tuo futuro' className='w-full h-full object-cover' />
            </div>
          </div>
        </div>
      </section>

      {/* contact form */}
      <div id='contatti'>
        <ContactForm/>
      </div>

      <Footer/>
    </div>
  )
}

export default HomePage