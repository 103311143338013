
import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from "../../components/Footer";
import { Helmet } from 'react-helmet';
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";
import mobileApp from '../../assets/media/novita-phone.png';
import appStore from '../../assets/icons/app-store.png';
import playStore from '../../assets/icons/google-play.png';
import img5 from '../../assets/icons/2-white.png';
import img6 from '../../assets/icons/6-white.png';
import coomingSoon from '../../assets/icons/home4.png';
import videoNovita from '../../assets/media/video-novita.mp4';

function Novita() {


  return (
    <div className="">
      <Helmet>
        <title></title>
        <meta name="description" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content={Logo} /> 
        <meta property="og:url" content="https://oxyera.com/" />
        <meta property="og:type" content="page" /> 
        <meta name="keywords" content="" />
        <meta name="author" content="" />
        <link rel="canonical" href="https://oxyera.com/" />
      </Helmet>
      <Navbar />

        {/* video section */}
        <div className='flex justify-center z-20 items-end sm:h-lvh overflow-hidden bg-[#dde1e7]'>
            <video
                className="sm:w-2/3 sm:py-20 h-[100vh] sm:h-[90vh] py-36 object-cover"
                autoPlay
                muted
                loop={false}
                playsInline
                controls={false}
            >
                <source src={videoNovita} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>



{/* 2nd Section */}
<section>
  <div className='mx-auto bg-blue-oxyera'>
    <div className='h-screen flex flex-col sm:flex-row justify-center items-center'>
      {/* Left Image Block (Visible on small screens and above) */}
      <div className='opacity-90 h-1/3 sm:h-full hidden sm:block sm:w-1/4 z-0'>
        <img
          src={mobileApp}
          alt='La scelta responsabile per il tuo futuro'
          className='w-full h-full object-cover'
        />
      </div>

      {/* Right Content Block */}
      <div className='h-2/3 w-full sm:h-full sm:w-3/4 bg-blue-oxyera flex justify-center items-center p-4 z-0'>
        <div className='text-left w-full max-w-xl sm:max-w-md lg:max-w-lg xl:max-w-xl mx-auto'>
          {/* Header */}
          <div className="flex justify-start items-center mb-4 sm:mb-2">
            <h2 className="text-4xl sm:text-5xl font-semibold text-white">Oxyera App</h2>
          </div>

          {/* Description */}
          <div
            data-aos="fade-zoom-in"
            data-aos-duration="1000"
            className='font-medium py-4 sm:py-9 text-md sm:text-2xl text-white text-balance'
          >
            Puoi cercare e scegliere i farmaci di cui hai bisogno direttamente dal tuo smartphone. Hai trovato ciò che ti serve?
          </div>

          {/* Features List */}
          <ul className='space-y-6 text-white'>
            <li className='flex items-center'>
              <img src={img5} alt="" className='h-10 sm:h-14 mr-4'/>
              <div>
                <b className='text-lg sm:text-2xl'>Prenota e ritira nella farmacia più vicina</b>
              </div>
            </li>
            <li className='flex items-top'>
              <img src={img6} alt="" className='h-10 sm:h-14 mr-4'/>
              <div>
                <div className='flex flex-row items-center'>
                  <b className='text-lg sm:text-2xl'>Richiedi la consegna dei</b>
                  <img src={coomingSoon} alt="" className='relative h-10 ms-5 hidden lg:block'/>
                </div>
                <b className='text-lg sm:text-2xl'>farmaci direttamente a casa tua</b>
                <img src={coomingSoon} alt="" className='relative h-10 md:ms-5 lg:hidden block'/>
              </div>
            </li>
          </ul>

          {/* Store Buttons */}
          <div className='flex flex-row justify-start items-center mt-6 sm:mt-10'>
            <img src={appStore} alt='App Store' className='w-32 sm:w-20 md:w-36 lg:w-46' />
            <img src={playStore} alt='Google Play Store' className='w-32 sm:w-20 md:w-36 lg:w-46 ms-4' />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


      <Footer/>
    </div>
  )
}

export default Novita